<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Tracks of a Killer - Week 1: Question 2</h2>

      <p class="mb-4">
        Consider the table of data below that was collected for a sample of oxygen gas at a constant
        pressure.
      </p>

      <v-simple-table style="margin-left: 15px; margin-bottom: 20px; width: 200px" dense>
        <thead>
          <th style="text-align: center">
            <stemble-latex content="$\text{V (L)}$" />
          </th>
          <th style="text-align: center">
            <stemble-latex content="$\text{T (}^\circ\text{C)}$" />
          </th>
        </thead>
        <tbody>
          <tr v-for="(volumeValue, i) in volumeValues" :key="volumeValue">
            <td style="text-align: center">
              {{ volumeValues[i].toFixed(2) }}
            </td>
            <td style="text-align: center">
              {{ tempValues[i].toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) Which property represents the independent variable? Explain your answer.
      </p>

      <p class="mb-3">
        <s-textarea auto-grow v-model="inputs.indVariable" class="mb-4" outlined />
      </p>

      <p class="mb-2">
        b) Prepare a graph of these data using Excel. Include a linear trendline on your graph to
        help you answer parts c) through e) of this question. Please, upload your graph using the
        input field below.
      </p>

      <p><b>Accepted file types</b>: PDF, JPG, PNG, XLSX, XLS</p>

      <v-row class="mb-5">
        <v-file-input v-model="graphUpload" multiple />
      </v-row>

      <p class="mb-2">
        c) Based on your graph, provide an approximate value for the volume of oxygen at
        <stemble-latex content="$100\,^\circ\text{C?}$" />
      </p>

      <calculation-input
        v-model="inputs.volAt100C"
        class="mb-6"
        prepend-text="$\text{V}_{\text{O}_2}:$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">d) Determine the slope of the line.</p>

      <calculation-input
        v-model="inputs.slopeInput"
        class="mb-6"
        prepend-text="$\text{Slope}:$"
        append-text="$\text{Units not provided}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) Assuming the y-intercept for the graph you constructed is
        <stemble-latex content="$-273.0\,^\circ\text{C,}$" />
        fill in the information below to construct the equation for the line of best fit for your
        data. For the dependent and independent variables, simply use V for volume and T for
        temperature where appropriate.
      </p>

      <v-text-field
        v-model="inputs.depVariable"
        class="mb-1"
        label="Dependent Variable:"
        :disabled="!allowEditing"
      />

      <v-text-field
        v-model="inputs.indepVariable"
        class="mb-2"
        label="Independent Variable:"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.interceptInput"
        class="mb-5"
        prepend-text="$\text{y-int}:$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        <stemble-latex :content="equation" />
      </p>
    </v-form>
  </div>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'OleMissExperiment9PrelabQuestion2',
  components: {CalculationInput, STextarea, AiLoadingOverlay, StembleLatex},
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        indVariable: null,
        volAt100C: null,
        slopeInput: null,
        interceptInput: null,
        indepVariable: null,
        depVariable: null,
      },
      graphUpload: [],
    };
  },
  computed: {
    slope() {
      return 12.17943;
    },
    intercept() {
      return -273;
    },
    volumeValues() {
      const start = 0;
      const stop = 6;
      return new Array(stop - start).fill(0).map((_, i) => 5 * i + 25.0);
    },
    tempValues() {
      return this.volumeValues.map((vol) => this.slope * vol + this.intercept);
    },
    equation() {
      if (this.inputs.depVariable === null) {
        return '$\\textbf{Equation: }\\text{Please enter dependent variable}$';
      } else if (this.inputs.slopeInput === null) {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = $`;
      } else if (this.inputs.indepVariable === null) {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = ${this.inputs.slopeInput}$`;
      } else if (this.inputs.interceptInput === null) {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = ${this.inputs.slopeInput}\\, ${this.inputs.indepVariable}$`;
      } else {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = ${this.inputs.slopeInput}\\, ${this.inputs.indepVariable} ${this.inputs.interceptInput}\\,^\\circ\\text{C}$`;
      }
    },
    attachments() {
      return [...this.graphUpload];
    },
  },
});
</script>
